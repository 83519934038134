





import Vue from 'vue';

import OnboardingContainer from '@/container/auth/OnboardingContainer.vue';

export default Vue.extend({
  components: {
    OnboardingContainer,
  },
});
