















































































import { sendEvent } from '@/service/GoogleAnalyticsService';
import LocalDataServices from '@/service/LocalDataServices';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import Vue from 'vue';

interface DataType {
  tabIndex: number;
  invitingEmails: string[];
  creatingTagNames: string[];
}

export default Vue.extend({
  data: (): DataType => {
    return {
      tabIndex: 0,
      invitingEmails: [''],
      creatingTagNames: [''],
    };
  },

  computed: {
    inviteButtonDisabled(): boolean {
      const validEmails = this.invitingEmails
                            .filter((email) => email !== '' && email.trim() !== '')
                            .filter((email) => this.isEmailValid(email));
      return validEmails.length === 0;
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    next() {
      this.tabIndex = this.tabIndex + 1;
    },
    isEmailValid(email: string) {
      if (!email) { return false; }
      const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      return emailRegExp.test(email);
    },
    focusEmailInput(index: number) {
      if (this.invitingEmails.length < 5) {
        if (index === this.invitingEmails.length - 1) {
          this.invitingEmails.push('');
        }
      }
    },
    focusTagInput(index: number) {
      if (index === this.creatingTagNames.length - 1) {
        this.creatingTagNames.push('');
      }
    },
    async inviteUsers() {
      const teamId = LocalDataServices.getTeamId();
      if (teamId) {
        const emails = this.invitingEmails.filter((email) => email.trim().length > 0);
        emails.filter((email) => !this.isEmailValid(email)).forEach((email) => {
          alert(`${this.$t('emailInvalidAlertPre')} ${email} ${this.$t('emailInvalidAlertSuf')}`);
        });
        const validEmails = this.invitingEmails.filter((email) => this.isEmailValid(email));
        const uniqueEmails: string[] = [];
        validEmails.forEach((email) => {
          if (uniqueEmails.indexOf(email) < 0) {
            uniqueEmails.push(email);
          }
        });
        commonModule.setLoader(true);
        await Promise.all(uniqueEmails.map((email) => {
          return settingViewModule.inviteUser({ teamId, email });
        }));
        commonModule.setLoader(false);
        this.tabIndex = 1;
        sendEvent('invitation', {
          link: false,
          onboarding: true,
        });
        this.$intercom.trackEvent('invitation', {
          link: false,
          onboarding: true,
        });
      }
    },
    async createTags() {
      const tagNames = this.creatingTagNames.filter((name) => name.trim().length > 0) as string[];
      const teamId = LocalDataServices.getTeamId();
      const userId = LocalDataServices.getUserId();
      if (teamId && userId) {
        commonModule.setLoader(true);
        await Promise.all(tagNames.map((name) => {
          return tagModule.createTag({
            teamId,
            tag: name,
          });
        }));
        await userModule.finishOnboarding({ teamId, userId });
        commonModule.setLoader(false);
      }
      this.$router.push('/today');
    },
  },

  mounted() {
  }
});
