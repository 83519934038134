import LocalDataServices from "./LocalDataServices";

export const gaSetUserId = (userId: string, userType: string | null = null) => {
  window.gtag('config', 'GA_MEASUREMENT_ID', {
    'user_id': userId,
    'user_type': userType,
  });
};

export const sendTestEvent = () => {
  window.gtag('event', 'test_event', {
    'custom_prop_str': 'remonade',
    'custom_prop_num': 1.1,
  });
};

export const sendEvent = (eventName: string, props: any) => {
  const teamId = LocalDataServices.getTeamId() || null;
  window.gtag('event', eventName, { ...props, team_id: teamId });
};
